import React, { useState } from 'react'
import {IoDocumentTextOutline} from 'react-icons/io5'

const ListItem = ({ obj }) => {

    const [active, setActive] = useState(false);

    const toggle = (arg) => {
        setActive(arg);
    }
    return (
        <div
            onClick={() => toggle(!active)}
            className={`event ${active ? 'active' : 'inactive'}`}>
            <div className="row">
                <div className='toggler'></div>
                <p >{obj.name}</p>
                <p className='after'></p>
                <p>{obj.date}</p>
            </div>
            <div className="row">
                <p>Место проведения</p>
                <p className='after'></p>
                <p>{obj.place}</p>
            </div>
            <div className="row">
                <p>Регламент</p>
                <p className='after'></p>
                <a href={process.env.PUBLIC_URL + `/files/Events/${obj.name}.pdf`} target="_blank" rel="noreferrer">
                <IoDocumentTextOutline 
                size={"2.6rem"}
                className='reg'/>
                </a>
            </div>
        </div>
    )
}

export default ListItem