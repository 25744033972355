import React from 'react'

import btn from "../../assets/img/news_button.svg"

function News({ NEWS_DATA_1, NEWS_DATA_2 }) {
  const addDefaultSrc = (ev) => {
    ev.target.src = process.env.PUBLIC_URL + '/images/default_bg.png';
  }
  
  return (
    <div className='news'>
      <h1>НОВОСТИ</h1>
      <div className='news-container'>
        <div className='actual-new'>
          <div className='new-text'>
            <p className='title'>{NEWS_DATA_1.title}</p>
            <p className='text'>{NEWS_DATA_1.text}</p>
          </div>
          <a href={NEWS_DATA_1.link} className="new-btn-1" target="_blank" rel="noreferrer">
            <img src={btn} alt="btn"></img>
          </a>
          <div className='new-img'>
          
            <img onError={addDefaultSrc} src={process.env.PUBLIC_URL + `/images/News/new_${NEWS_DATA_1.id}.png`} alt="new" />
          </div>
        </div>
        <div className='actual-new'>
          <div className='new-img'>
            <img onError={addDefaultSrc} src={process.env.PUBLIC_URL + `/images/News/new_${NEWS_DATA_2.id}.png`} alt="new" />
          </div>
          <div className='new-text'>
            <p className='title'>{NEWS_DATA_2.title}</p>
            <p className='text'>{NEWS_DATA_2.text}</p>
          </div>
          <a href={NEWS_DATA_2.link} className="new-btn-2" target="_blank" rel="noreferrer">
            <img src={btn} alt="btn"></img>
          </a>
        </div>
      </div>
    </div>
  )
}

export default News