import React from 'react'

import Footer from '../Footer'

const Other = ({ id, OTHER_DATA }) => {

  const addDefaultSrc = (ev) => {
    ev.target.src = process.env.PUBLIC_URL + '/images/default_bg.png';
  }

  return (
    <div id={id} className='Other'>
      <h2>ДРУГИЕ МЕРОПРИЯТИЯ</h2>
      {OTHER_DATA.map((obj) =>
        <div key={obj.id} className="event">
          <div className="image">
            <img onError={addDefaultSrc} src={process.env.PUBLIC_URL + `/images/Events/Other/${obj.id}.png`} alt={obj.id} />
          </div>
          <div className='line'/>
          <img className='point' src={ require('../../assets/img/point.png')} alt="point" />
          <div className={obj.id%2===0?'info even':'info'}>
            <p className='date'>{obj.date}</p>
            <p className='name'>{obj.name}</p>
          </div>
        </div>
      )}
      <Footer/>
    </div>
  )
}

export default Other