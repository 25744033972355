import React from 'react'

function Rating({ RATING_DATA }) {

  const addDefaultSrc = (ev) => {
    ev.target.src = process.env.PUBLIC_URL + '/images/default_photo.png';
  }

  return (
    <div className='rating'>
      <h1>РЕЙТИНГ</h1>
      <div className="rating-container">
        <div className="top">
          {
            <>
              <div className="logo-second">
                <img
                  onError={addDefaultSrc}
                  src={process.env.PUBLIC_URL + `/images/University/${RATING_DATA[1].name}/${RATING_DATA[1].name}.png`}
                  alt="uni"
                />
              </div>
              <div className="logo-first">
                <img
                  onError={addDefaultSrc}
                  src={process.env.PUBLIC_URL + `/images/University/${RATING_DATA[0].name}/${RATING_DATA[0].name}.png`}
                  alt="uni"
                />
              </div>

              <div className="logo-third">
                <img
                  onError={addDefaultSrc}
                  src={process.env.PUBLIC_URL + `/images/University/${RATING_DATA[2].name}/${RATING_DATA[2].name}.png`}
                  alt="uni"
                />
              </div>
            </>
          }
        </div>
        <div className="list">
          {
            RATING_DATA.map((obj, index) => {
              return (

                <div key={obj.name} className='list-item'>
                  <p className='place'>{`${index + 1} место`}</p>
                  <p>{obj.name}</p>
                </div>

              )
            })
          }
        </div>

      </div>
    </div>
  )
}

export default Rating