import React from 'react'

import Scrollable from '../scrollable/Scrollable'

const Partners = ({ PARTNERS_DATA }) => {
    const addDefaultSrc = (ev) => {
        ev.target.src = process.env.PUBLIC_URL + '/images/default_photo.png';
    }
    return (
        <div className='Partners'>
            <h1>ПАРТНЁРЫ</h1>
            <div className="partners-container">
                <Scrollable>
                    {PARTNERS_DATA.map((obj) =>
                        <div
                            key={obj.name}
                            className="partner">
                            <a
                                href={obj.link || "#"}
                                target={obj.link?'_blank':'_self'}
                                rel="noreferrer">
                                <img
                                    onError={addDefaultSrc}
                                    src={process.env.PUBLIC_URL + `/images/Partners/${obj.name}.png`} alt="" />
                            </a>
                        </div>)}
                </Scrollable>
            </div>
        </div>
    )
}

export default Partners