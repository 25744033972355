import React from 'react'


const NewPost = ({ id, title, text, link, date, image }) => {
    const addDefaultSrc = (ev) => {
        ev.target.src = process.env.PUBLIC_URL + '/images/default_bg.png';
      }

    return (
        <div className='NewPost'>
            <img onError={addDefaultSrc} src={process.env.PUBLIC_URL + `/images/News/new_${id}.png`} alt={title} />
            <div className={`new_text ${id%2 === 0?'even':'odd'}`}>
                <h4>{title}</h4>
                <p>{text}</p>
                <a href={link} target="_blank" rel='noreferrer'>Читать в источнике</a>
                <span>{date}</span>
            </div>
        </div>
    )
}

export default NewPost