import React from 'react'

import vkLogo from "../../assets/img/vk.svg"
import ytLogo from "../../assets/img/yt.svg"
import tgLogo from "../../assets/img/te.svg"

function Links() {
  return (
    <div className='links'>
        <a href='vk.com'>
            <img src={vkLogo} alt="vk"></img>
        </a>
        <a href='tg'>
            <img src={tgLogo} alt='tg'></img>
        </a>
        <a href='youtube'>
            <img src={ytLogo} alt='youtube'></img>
        </a>
    </div>
  )
}

export default Links