import React from 'react'

import Header from '../components/Header'

const Media = () => {
  return (
    <div className='Media'>
      <Header title='Медиа' />
    </div>
  )
}

export default Media