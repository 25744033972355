import { Route, Routes } from 'react-router-dom';
import { Home, Events, Participants, News, Media } from "./pages";

import EVENTS_DATA from './assets/JSON/EVENTS.json'
import NEWS_DATA from './assets/JSON/NEWS.json'
import OTHER_DATA from './assets/JSON/OTHER.json'
import UNIVERSITIES_DATA from './assets/JSON/UNIVERSITIES.json'
import PARTNERS_DATA from './assets/JSON/PARTNERS.json'
import RATING_DATA from './assets/JSON/RATING.json'



function App() {
  const UNI_DATA_SIZE = UNIVERSITIES_DATA.length;
  return (
    <div className="App">
      <Routes>
        <Route exact path='/' element={<Home NEWS_DATA_1={NEWS_DATA[0]||null} NEWS_DATA_2={NEWS_DATA[1]||null} PARTNERS_DATA={PARTNERS_DATA} RATING_DATA={RATING_DATA} />} />
        <Route path='/news' element={<News NEWS_DATA={NEWS_DATA}/>} />
        <Route path='/participants' element={<Participants UNIVERSITIES_DATA={UNIVERSITIES_DATA} UNI_DATA_SIZE={UNI_DATA_SIZE}/>} />
        <Route path='/events' element={<Events EVENTS_DATA={EVENTS_DATA} OTHER_DATA={OTHER_DATA}/>} />
        <Route path='/media' element={<Media/>} />
      </Routes>
    </div>
  );
}

export default App;
