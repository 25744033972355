import React from 'react'

import Header from '../components/Header'
import NewPost from '../components/News/NewPost'
import Footer from '../components/Footer'

const News = ({NEWS_DATA}) => {
  return (
    <div className='News'>
    <Header title='НОВОСТИ'/>
    {NEWS_DATA.map((obj) => 
      <NewPost key={obj.id} {...obj} />
    )}
    <Footer/>
    </div>
  )
}

export default News