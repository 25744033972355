import React from 'react'

const Information = ({ select, card, controls, chooseUni, addDefaultSrc, chooseControl, setCard }) => {
    const addDefaultSrcPhoto = (ev) => {
        ev.target.src = process.env.PUBLIC_URL + `/images/University/${select.name}/${select.name}.png`;
    }
    return (
        <div className={`uni ${select.name ? '' : 'inactive'}`} id="Info">
            <div className="head">
                {select.name && (<img
                    onError={addDefaultSrc}
                    key={select.name}
                    src={process.env.PUBLIC_URL + `/images/University/${select.name}/${select.name}.png`}
                    alt="uni"
                    onClick={() => chooseUni(select.name)}
                />)}
                <div className='names'>
                    <h2>{select.clubname || select.name}</h2>
                    <h3>{select.clubname && select.name}</h3>
                </div>
                <div className='controls'>
                    <span onClick={() => chooseControl(true)} className={controls ? 'selected' : ''}>О вузе</span>
                    <span onClick={() => chooseControl(false)} className={controls ? '' : 'selected'}>Детали</span>
                </div>

            </div>

            <div className="body">
                <div className={`about ${controls ? '' : 'inactive'}`}>
                    <p>{select.about}</p>
                    <div className="contacts">
                        <h3>Контакты</h3>
                        {select.phone && (
                            <p>{select.phone}</p>
                        )}
                        {select.mail && (
                            <p>{select.mail}</p>
                        )}
                        {select.address && (
                            <p>{select.address}</p>
                        )}
                    </div>
                </div>
                <div className={`details ${controls ? 'inactive' : ''}`}>
                    <div className='card'>

                        <div className="personal_card">
                            <img
                                onError={addDefaultSrcPhoto}
                                src={process.env.PUBLIC_URL + `/images/University/${select.name}/${card.category}/${select[`${card.category}`][card.index].name}.png`}
                                alt="" />
                            <div className="info">
                                <p>{select[`${card.category}`][card.index].name}</p>
                                {card.category === 'team' && (
                                    <>
                                        <p>Разряд</p>
                                        <p className='secondary'>{select[`${card.category}`][card.index].qual || 'Отсутствует'}</p>
                                        <p>Достижения</p>
                                        <ul className='secondary'>

                                            {(select[`${card.category}`][card.index].achievements.length !== 0 && select[`${card.category}`][card.index].achievements.map(obj => {
                                                return (
                                                    <li className='secondary' key={obj}>{obj}</li>
                                                )
                                            })) || "Отсутствуют"}
                                        </ul>
                                    </>
                                )}
                                {card.category === 'coaches' && (
                                    <>
                                        <p>Достижения</p>
                                        <ul className='secondary'>

                                            {(select[`${card.category}`][card.index].qual.length !== 0 && select[`${card.category}`][card.index].qual.map(obj => {
                                                return (
                                                    <li className='secondary' key={obj}>{obj}</li>
                                                )
                                            })) || "Отсутствуют"}
                                        </ul>
                                    </>
                                )}
                                {card.category === 'managers' && (
                                    <>
                                        <p>Должность</p>
                                        <p className='secondary'>{select[`${card.category}`][card.index].post || 'Не указана'}</p>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                    <div className='list'>
                        <div>
                            <h3>Руководители</h3>
                            {select.managers && select.managers.map((obj, index) => {
                                return (
                                    <p
                                        className={(card.category === 'managers') && (card.index === index) ? 'selected' : ''}
                                        onClick={() => setCard({ category: 'managers', index: index })}
                                        key={obj.name}>{obj.name}</p>
                                )
                            })}
                        </div>
                        <div>
                            <h3>Тренеры</h3>
                            {select.coaches && select.coaches.map((obj, index) => {
                                return (
                                    <p
                                        className={(card.category === 'coaches') && (card.index === index) ? 'selected' : ''}
                                        onClick={() => setCard({ category: 'coaches', index: index })}
                                        key={obj.name}>{obj.name}</p>
                                )
                            })}
                        </div>
                        <div>
                            <h3>Команда</h3>
                            {select.team && select.team.map((obj, index) => {
                                return (
                                    <p
                                        className={(card.category === 'team') && (card.index === index) ? 'selected' : ''}
                                        onClick={() => setCard({ category: 'team', index: index })}
                                        key={obj.name}>{obj.name}</p>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Information