import React from 'react'

const Results = ({id}) => {
  return (
    <div id={id} className='Results'>
      <h2>РЕЗУЛЬТАТЫ</h2>
    </div>
  )
}

export default Results