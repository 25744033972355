import React from 'react'
import { Links } from './Home'

const Footer = () => {
  return (
    <section className='Footer'>
        <p className='date'>© 2016-2022</p>
        <p className='name'>Студенческая лига Джиу-джитсу</p>
        <Links/>
    </section>
  )
}

export default Footer