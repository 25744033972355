import React from 'react'

const List = ({ UNIVERSITIES_DATA, addDefaultSrc, chooseUni, select, classN }) => {
    return (
        <div className={`List ${classN}`}>
            {UNIVERSITIES_DATA.map((uni) =>
                <div className={uni.name === select.name ? 'uni-img-cont active' : 'uni-img-cont'} key={uni.name}>
                    <img
                        onError={addDefaultSrc}
                        src={process.env.PUBLIC_URL + `/images/University/${uni.name}/${uni.name}.png`}
                        alt="uni"
                        onClick={() => chooseUni(uni.name)}
                    />
                </div>
            )}
        </div>
    )
}

export default List