import React from 'react'

import {Rating, News, Media, Partners} from "../components/Home"
import Header from '../components/Header'
import Footer from '../components/Footer'

function Home( { NEWS_DATA_1, NEWS_DATA_2, PARTNERS_DATA, RATING_DATA } ) {
  return (
    <div className='home'>
    <Header/>
    <Rating RATING_DATA={RATING_DATA} />
    <News NEWS_DATA_1={NEWS_DATA_1} NEWS_DATA_2={NEWS_DATA_2} />
    <Media/>
    <Partners PARTNERS_DATA={PARTNERS_DATA}/>
    <Footer/>
    </div>
  )
}

export default Home