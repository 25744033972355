import React from 'react'

const SliderCard = ({uni ,addDefaultSrc, chooseUni, select}) => {
    return (
        <div className={uni.name === select.name ? 'uni-img-cont active' : 'uni-img-cont'} key={uni.name}>
            <img
                onError={addDefaultSrc}
                src={process.env.PUBLIC_URL + `/images/University/${uni.name}/${uni.name}.png`}
                alt="uni"
                onClick={() => chooseUni(uni.name)}
            />
        </div>
    )
}

export default SliderCard