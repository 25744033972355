import React from 'react'

function Media() {
  return (
    <div className='media'>
      <h1>МЕДИА</h1>
    </div>
  )
}

export default Media