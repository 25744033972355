import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom';

import { Button, Links } from "../components/Home"

import logo from "../assets/img/logo.svg"

function Menu({ hidden }) {
    const [eventsChoosen, setEventsChoosen] = useState(false);

    return (
        <div className={`Menu ${hidden ? 'hidden' : 'active'}`}>
            <Link to="/">
                <img src={logo} alt="" className='logo'></img>
            </Link>
            <Links />

            <div className='links_container'>
                <div className="main_links">
                    <Link to='/news'>
                        <Button className={eventsChoosen ? "menu_links-inactive" : "menu_links"}>НОВОСТИ</Button>
                    </Link>
                    <Link to='/participants'>
                        <Button className={eventsChoosen ? "menu_links-inactive" : "menu_links"}>УЧАСТНИКИ</Button>
                    </Link>
                    <Button className="menu_links" onClick={() => setEventsChoosen(!eventsChoosen)}>МЕРОПРИЯТИЯ</Button>
                    <Link to='/media'>
                        <Button className={eventsChoosen ? "menu_links-inactive" : "menu_links"}>МЕДИА</Button>
                    </Link>
                   
                </div>
                <div className="secondary_links">
                    <Link to='/events'>
                        <Button className={eventsChoosen ? "event_links" : "event_links-inactive"}>МЕРОПРИЯТИЯ</Button>
                    </Link>
                    <Link to={{pathname:'/events',  hash:'event'}}>
                        <Button className={eventsChoosen ? "event_links" : "event_links-inactive"}>КАЛЕНДАРЬ МЕРОПРИЯТИЙ</Button>
                    </Link>
                    <Link to={{pathname:'/events', hash:'result'}}>
                        <Button className={eventsChoosen ? "event_links" : "event_links-inactive"}>РЕЗУЛЬТАТЫ</Button>
                    </Link>
                    <Link to={{pathname:'/events', hash:'other'}}>
                        <Button className={eventsChoosen ? "event_links" : "event_links-inactive"}>ДРУГИЕ МЕРОПРИЯТИЯ</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Menu