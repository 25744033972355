import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import scrollIntoView from 'scroll-into-view'


import Header from '../components/Header'
import { Calendar, Results, Other } from '../components/Events'


const Events = ({ EVENTS_DATA, OTHER_DATA }) => {
  const history = useLocation();

  useEffect(() => {

    const element = document.getElementById(history.hash)
    history.hash && scrollIntoView(element)


  }, [history]);


  return (
    <div className='Events'>
      <Header title='МЕРОПРИЯТИЯ' />
      <Calendar id={'#event'} EVENTS_DATA={EVENTS_DATA} />
      <Results id={'#result'} />
      <div className="buff" />
      <Other id={'#other'} OTHER_DATA={OTHER_DATA} />

    </div>
  )
}

export default Events