import { Link } from 'react-router-dom';
import React, { useState } from 'react'
import scrollIntoView from 'scroll-into-view'


import Button from './Home/Button'
import Links from './Home/Links'
import Menu from '../pages/Menu'

import logo from '../assets/img/logo.svg'

function Header({ title }) {
  const [menuHidden, setMenuHidden] = useState(true);
  const scroll = () => {
    const element = document.getElementById('Header').nextElementSibling;
    scrollIntoView(element);
  }
  return (
    <div className='Header' id='Header'>
    <Menu hidden={menuHidden}/>
      <Link to='/'>
        <img src={logo} alt="logo" className='logo'></img>
      </Link>
      <Button className={`menu_btn ${menuHidden?'':'active'}`} onClick={()=>setMenuHidden(!menuHidden)}>МЕНЮ</Button>
      {title ? <h1 className='pageTitle' onClick={scroll}>{title}</h1> : ""}
      <Links />
    </div>
  )
}

export default Header