import React, { useState } from 'react'
import scrollIntoView from 'scroll-into-view'


import Header from '../components/Header'
import Footer from '../components/Footer';
import SliderCard from '../components/Participants/SliderCard';
import Information from '../components/Participants/Information';
import List from '../components/Participants/List';

import CarouselCompound from '../components/carousel-compound'

const Participants = ({ UNIVERSITIES_DATA, UNI_DATA_SIZE }) => {

  const deffaultState = {
    "name": "",
    "clubname": "",
    "about": "",

    "phone": "",
    "mail": "",
    "address": "",
    "team": [
      {
        "name": "",
        "achievements": [],
        "qual": ""
      }
    ],
    "coaches": [
      {
        "name": ""
      }
    ],
    "managers": [
      {
        "name": "",
        "post": ""
      }
    ]
  }

  const [select, setSelect] = useState(deffaultState);
  const [controls, setControls] = useState(true);
  const [card, setCard] = useState({ category: 'team', index: 0 });


  const countPages = UNI_DATA_SIZE / 5;
  const PAGES_SLIDER = [];
  for (let i = 0; i < countPages; i++) {
    PAGES_SLIDER.push(UNIVERSITIES_DATA.slice((i * 5), Math.min((i * 5 + 5), UNI_DATA_SIZE)))
  }

  const chooseUni = (name) => {
    if (name === select.name) {
      setSelect(deffaultState)
    } else {
      setSelect(UNIVERSITIES_DATA.find(obj => obj.name === name));
      const element = document.getElementById('Info')
      scrollIntoView(element, {
        time: 10000,
        ease: function(value){
          return Math.pow(value,2) + value; // Do something weird.
      },
      })
    }
    setCard({ category: 'team', index: 0 });
    setControls(true);
  }

  const chooseControl = (bool) => {
    setControls(bool);
    setCard({ category: 'team', index: 0 });
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = process.env.PUBLIC_URL + '/images/default_photo.png';
  }

  return (
    <div className='Participants'>
      <Header title='Участники' />
      <div className="uni_layout">
        <div className="uni_list">
          <List
            classN={select.name ? 'inactive' : 'active'}
            UNIVERSITIES_DATA={UNIVERSITIES_DATA}
            addDefaultSrc={addDefaultSrc}
            chooseUni={chooseUni}
            select={select}
          />
          <CarouselCompound classN={select.name ? 'active' : 'inactive'}>
            {
              PAGES_SLIDER.map((arr, index) => {
                return (
                  <CarouselCompound.Page key={index}>

                    {arr.map((obj) =>
                      <SliderCard key={obj.name} uni={obj} addDefaultSrc={addDefaultSrc} chooseUni={chooseUni} select={select} />
                    )
                    }
                  </CarouselCompound.Page>
                )
              })
            }
          </CarouselCompound>

        </div>

        <Information
          select={select}
          card={card}
          controls={controls}
          addDefaultSrc={addDefaultSrc}
          chooseUni={chooseUni}
          chooseControl={chooseControl}
          setCard={setCard}
        />

      </div>
      <Footer />
    </div>
  )
}

export default Participants