import React from 'react'

import ListItem from './ListItem'

const Calendar = ({ EVENTS_DATA, id }) => {
  return (
    <div id={id} className='Calendar'>
      <h2>КАЛЕНДАРЬ МЕРОПРИЯТИЙ</h2>
      <div className="container">
        <div className="nearest">
          <div className='octagon-white'></div>
          {EVENTS_DATA[0] &&
            <div className='octagon first'>
              <p className='event_date'>{EVENTS_DATA[0].date}</p>
              <p className='event_name'>{EVENTS_DATA[0].name}</p>
            </div>}
          {EVENTS_DATA[1] &&
            <div className='octagon second'>
              <p className='event_date'>{EVENTS_DATA[1].date}</p>
              <p className='event_name'>{EVENTS_DATA[1].name}</p>
            </div>}
          {EVENTS_DATA[2] &&
            <div className='octagon third'>
              <p className='event_date'>{EVENTS_DATA[2].date}</p>
              <p className='event_name'>{EVENTS_DATA[2].name}</p>
            </div>}
        </div>
        <div className="all">
          <div className="title">
            <p>Турнир</p>
            <p className='after'></p>
            <p>Дата</p>
          </div>
          <div className="event_list">
            {EVENTS_DATA.map((obj) =>
            <ListItem obj={obj} key={obj.id}/>
              
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Calendar